path {
    transition: all .15s ease-in-out;
    cursor: pointer;
}

path:hover {
    fill: #DFA41A;
    scale: 1.01;
}

.cambio {
    cursor: pointer;
    transition: all .2s ease-in-out;
    filter: grayscale(100%)
}

.cambio:hover {
    filter: grayscale(0%)
}